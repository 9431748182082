import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import EulaTextComponent from '../../../components/Admin/Components/EulaText/EulaTextComponent';

const mapStateToProps = state => {
  return {
    selectedTheme: state.auth.data?.settings?.theme
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EulaTextComponent);