import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import MessagesComponent from '../../components/ChatComponent/Messages';
import { sendMessage } from "../../reducers/twilio";
import { removeMessage, acknowledgeMessage } from "../../reducers/chatRooms";

const mapStateToProps = state => {
  return {
    user: state.auth.data,
    contacts: state.contacts.list,
    licensing: state.setting.licensing
  };
};



const mapDispatchToProps = dispatch => bindActionCreators({
  sendMessage,
  removeMessage,
  acknowledgeMessage
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessagesComponent);
