import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import LicensingComponent from '../../../components/Admin/Components/Licensing/Licensing';
import { fetchAppSettings, updateLicensing } from '../../../reducers/setting';


const mapStateToProps = state => {
  return {
    licensing: state.setting.licensing,
    loading: state.setting.loading,
    userIsSuperAdmin: state.auth.data.superadmin,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchAppSettings,
  updateLicensing
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LicensingComponent);
