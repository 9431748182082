import React, { Component } from "react";
import _ from "lodash";
import moment from "moment";
import { decryptMessage } from "../../helpers/messages";
import {
  chatStatusClassName,
  contactDisplayName,
  renderSystemMessageText,
} from "../../helpers/chat";
import { UserAvatar, Loader } from "../UIComponents";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { systemMessages } from "../../config";
import * as xss from "xss";
import linkifyStr from "linkifyjs/string";
import { ToastrService } from "../../services";
import MediaGalleryModal, {
  useMediaGalleryModal,
} from "./MediaGalleryModal/MediaGalleryModal";
import { ImageS3 } from "./helpers/ImageS3";
import {
  CopyIcon,
  DeleteIcon,
  ImportantIcon,
  SMSIcon,
  UrgentIcon,
  Check
} from "./helpers/icons";
import { MessageViewList } from "./helpers/MessageViewList";
import { S3AudioPlayer } from "./VoiceMessage/AudioPlayer";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";

const MAX_SCROLL_TOP_TO_LOAD_MORE_MESSAGES = 200;
const MIN_SCROLL_TOP_TO_LOAD_MORE_MESSAGES = 50;

class MessagesComponent extends Component {
  container = React.createRef();

  state = {
    prevScrollHeight: 0,
    selectedMessageId: "",
    mediaModal: { isOpen: true },
  };

  componentDidMount() {
    this.scrollToBottom();
    if (this.props.linkedMessageLog && this.props.messages) {
      this.scrollToSelected(this.props.linkedMessageLog.msgId)
    }
    document.addEventListener("keydown", this.escapeKeyPress);
  }

  scrollToSelected(msgId) {
    setTimeout(() => {
      const element = document.getElementById(msgId);
      if (element) element.scrollIntoView();
    }, 1000)
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escapeKeyPress);
  }

  escapeKeyPress = (e) => {
    if (e.key === "Escape") {
      if (this.state.mediaModal.media) {
        this.setState({ mediaModal: {} });
      } else if (this.state.selectedMessageId) {
        this.setState({ selectedMessageId: "" });
      }
    }
  };

  componentDidUpdate(prevProps) {
    const { chatRoom, loadingMore, amountOfMessages } = this.props,
      {
        loadingMore: prevLoadingMore,
        chatRoom: prevChatRoom,
        amountOfMessages: prevAmountOfMessages,
      } = prevProps;
    if (
      (chatRoom && chatRoom.twId) !== (prevChatRoom && prevChatRoom.twId) ||
      (chatRoom && chatRoom.typingMembers && chatRoom.typingMembers.length) !==
      (prevChatRoom &&
        prevChatRoom.typingMembers &&
        prevChatRoom.typingMembers.length) ||
      amountOfMessages > prevAmountOfMessages
    ) {
      this.scrollToBottom();
      this.props.markChatRoomMessagesAsRead(prevChatRoom.twId)
    }

    if (!loadingMore && prevLoadingMore && this.state.prevScrollHeight) {
      this.container.current.scrollTop =
        this.container.current.scrollHeight - this.state.prevScrollHeight;
    }
  }

  scrollToBottom = () => {
    this.container.current.scrollTop = this.container.current.scrollHeight;
  };

  renderNoMessageScreen() {
    return (
      <div className="noMessageContainer d-flex align-items-center justify-content-center w-100 flex-column">
        <p className="wavingHandEmoji">👋</p>
        <p>{this.props.t('chat.no_history')}</p>
        <p>{this.props.t('chat.spark_up_conv')}</p>
      </div>
    );
  }

  handleScroll = () => {
    if (
      !!this.container &&
      !!this.container.current &&
      this.container.current.scrollHeight !==
      this.container.current.clientHeight
    ) {
      if (
        this.container.current.scrollTop <
        (this.container.current.scrollHeight -
          this.container.current.clientHeight <
          MAX_SCROLL_TOP_TO_LOAD_MORE_MESSAGES
          ? MIN_SCROLL_TOP_TO_LOAD_MORE_MESSAGES
          : MAX_SCROLL_TOP_TO_LOAD_MORE_MESSAGES)
      ) {
        this.setState(
          {
            prevScrollHeight:
              this.container.current.scrollTop +
              this.container.current.scrollHeight,
          },
          () => this.props.loadMore()
        );
      }
    }
  };

  selectMessage = (id) => {
    const selectedMessageId = id !== this.state.selectedMessageId && id;
    this.setState({ selectedMessageId });
  };

  renderSystemMessageIcon(message = "") {
    let iconName = "";

    const lowerCaseMessage = message.toLowerCase();

    const callType = lowerCaseMessage.includes("video")
      ? "video"
      : lowerCaseMessage.includes("screen share")
        ? "direct-screen-share"
        : "voice";

    switch (message) {
      case systemMessages.CALL_LEFT:
      case systemMessages.VIDEO_CALL_LEFT:
      case systemMessages.DIRECT_SCREEN_SHARE_LEFT:
      case systemMessages.DIRECT_SCREEN_SHARE_ENDED:
      case systemMessages.CALL_ENDED:
      case systemMessages.VIDEO_CALL_ENDED:
        iconName = `${callType}-ended`;
        break;
      case systemMessages.CALL_DECLINED:
      case systemMessages.VIDEO_CALL_DECLINED:
      case systemMessages.DIRECT_SCREEN_SHARE_DECLINED:
      case systemMessages.CALL_ABORTED:
      case systemMessages.DIRECT_SCREEN_SHARE_ABORTED:
      case systemMessages.VIDEO_CALL_ABORTED:
        iconName = `${callType}-declined`;
        break;
      case systemMessages.CALL_MISSED:
        iconName = `${callType}-missed`;
        break;
      case systemMessages.USER_ADDED:
      case systemMessages.USER_REMOVED:
        iconName = null;
        break;
      case systemMessages.USER_LEFT:
        iconName = "user-left";
        break;
      case systemMessages.USER_JOINED:
        iconName = "user-joined";
        break;
      default: {
        if (message.indexOf(systemMessages.USER_ADDED) !== -1) {
          iconName = "user-added";
        } else if (message.indexOf(systemMessages.USER_REMOVED) !== -1) {
          iconName = "user-removed";
        } else if (message.indexOf(systemMessages.USER_LEFT) !== -1) {
          iconName = "user-left";
        } else if (message.indexOf(systemMessages.USER_JOINED) !== -1) {
          iconName = "user-joined";
        } else {
          iconName = `${callType}-started`;
        }
      }
    }

    return (
      iconName && (
        <img src={`/assets/images/${iconName}.svg`} width="20" alt="message" />
      )
    );
  }

  renderMessages() {
    const { messages, contacts, user, chatRoom, t, licensing } = this.props;
    const { selectedMessageId } = this.state;
    const isGroup = chatRoom.type === "group";

    return _.keys(messages).map((date, index) => {
      const messagesList = messages[date];
      return (
        <div key={`${date}-${index}`}>
          <div className="messagesGroupDateContainer">
            <div className="divider" />
            <p className="messagesGroupDate">{date}</p>
            <div className="divider" />
          </div>
          {messagesList.map((message, i) => {
            const messageTime = moment(message.timetoken).format(i18n.language === 'fr' ? "HH:mm" : "h:mm A");
            const decryptedText = decryptMessage(message.body);
            let messageText = linkifyStr(decryptedText);
            const re = /([^\"=]{2}|^)((https?|ftp):\/\/\S+[^\s.,> )\];'\"!?])/;
            const subst = '$1<a href="$2" target="_blank">$2</a>';
            const withLinkMessageText = messageText.replace(re, subst);

            if (message.attributes?.systemMessage) {
              if (
                message.attributes.toAll ||
                message.attributes.to?.includes(user.id)
              ) {
                const sentByUser =
                  message.attributes.event?.data?.initiator === user?.id ||
                  message.attributes.event?.data?.initiator?.includes(user?.id);

                if (sentByUser && messageText === systemMessages.USER_REMOVED) {
                  return null;
                }

                const initiator = message.attributes.event?.data?.initiator;

                const messageSender = sentByUser
                  ? user
                  : Array.isArray(initiator)
                    ? initiator.map((id) => contacts.find((c) => c.id === id))
                    : contacts.find((contact) => contact.id === initiator);
                return (
                  <div key={i} className="systemMessageContainer">
                    {this.renderSystemMessageIcon(messageText)}
                    <p className="systemMessage">
                      {renderSystemMessageText(
                        messageText,
                        messageSender,
                        sentByUser,
                        message.attributes
                      )}
                    </p>
                    <p className="messageSentTime">{messageTime}</p>
                  </div>
                );
              }
              return null;
            }

            const sentByUser = user.id === (message.from && message.from.id);
            const canDelete = sentByUser && user.permissions.delSms !== false;
            const smsPermission = licensing.sendSMS && user?.permissions?.sms !== false;

            const messageSender = sentByUser
              ? user
              : contacts.find(
                (contact) => contact.id === (message.from && message.from.id)
              );
            const messageSenderDisplayName = sentByUser
              ? i18n.t("You")
              : contactDisplayName(messageSender);
            const imagesLength = message.attributes?.files?.length || 0;
            const images = message.attributes?.files;
            let importance = message.attributes?.level || "_";

            if (importance !== "_") {
              importance =
                sentByUser || message.attributes.notify?.includes(user.id)
                  ? importance
                  : "_";
            }

            const canAcknowledge =
              importance !== '_' && !sentByUser &&
              Array.isArray(message.attributes.acknowledge) &&
              message.attributes.acknowledge.findIndex(x => x.userId === user.id) < 0;

            const isAcknowledged = sentByUser && message.attributes.acknowledge?.length > 0 && message.attributes.acknowledge?.length >= message.attributes.notify?.length 

            let clipboardText =
              imagesLength > 0
                ? `::::${JSON.stringify({
                  text: decryptedText,
                  images: message.attributes?.files,
                })}::::`
                : decryptedText;

            const hasVoice = !!message.attributes?.voice?.key;

            if (hasVoice) {
              clipboardText = `::::${JSON.stringify({
                text: decryptedText,
                voice: message.attributes?.voice?.key,
                duration: message.attributes?.voice?.duration
              })}::::`
            }

            return (
              <div
                key={message.id}
                id={message.id}
                className={`messageItemContainer${sentByUser ? " alignRight" : ""
                  }`}
              >
                <UserAvatar
                  user={messageSender}
                  className={`withStatus mr-2 ${(sentByUser || messageSender?.connected) &&
                    messageSender.chatStatus
                    ? chatStatusClassName(messageSender.chatStatus)
                    : "Offline"
                    }`}
                />
                <div>
                  <div className="messageSenderNameAndTimeContainer">
                    <p className="messageSenderName">
                      {messageSenderDisplayName}
                    </p>
                    <p className="messageSentTime"> {messageTime}</p>
                  </div>
                  <div
                    className={
                      "messageTextPartContainer" +
                      (imagesLength > 0 ? " has_files" : " no_files")
                    }
                  >
                    <div
                      className={`messageTextContainer 
                      ${importance}${sentByUser ? ' me' : ' they'}
                      ${imagesLength > 0 ? ' images' : ''}
                      ${hasVoice ? ' voice' : ''}
                      ${isAcknowledged ? ' acknowledged' : ''}
                      `}
                      onClick={() => this.selectMessage(message.id)}
                    >
                      {importance !== "_" && (
                        <>
                          <div className="importance">
                            <big>{importance}!</big>
                            <span>
                              {isAcknowledged ? <Check /> : (
                                importance === "URGENT" ? (
                                  <UrgentIcon />
                                ) : (
                                  <ImportantIcon />
                                )
                              )

                              }

                            </span>
                          </div>
                        </>
                      )}
                      <p
                        dangerouslySetInnerHTML={{
                          __html: xss(withLinkMessageText),
                        }}
                      />
                      <div
                        className={`imagesContainer  images_${imagesLength > 5 ? 5 : imagesLength
                          }`}
                      >
                        <div>
                          {images?.map((file, i) => {
                            if (i < 6)
                              return (
                                <span
                                  key={i}
                                  onClick={(e) => {
                                    if (selectedMessageId === message.id) {
                                      this.setState({
                                        mediaModal: {
                                          media: message.attributes.files,
                                          index: i,
                                        },
                                      });
                                    } else {
                                      this.selectMessage(message.id);
                                    }
                                    e.stopPropagation()
                                  }}
                                >
                                  {imagesLength > 6 && i === 5 ? (
                                    <div className="more">
                                      +{imagesLength - 5}
                                    </div>
                                  ) : <ImageS3
                                    imgName={file.name}
                                    key={i}
                                    imgKey={file.key}
                                  />}
                                </span>
                              );
                            return null;
                          })}
                        </div>
                        <b>
                          {imagesLength === 1
                            ? images[0].name
                            : `${imagesLength} Images`}
                        </b>
                      </div>
                      {hasVoice && <div className="voice_container" onClick={(e) => {
                        e.stopPropagation()
                      }}>
                        <S3AudioPlayer
                          s3key={message.attributes?.voice?.key}
                          duration={message.attributes?.voice?.duration}
                          onClick={() => this.selectMessage(message.id)}
                        />
                      </div>}
                    </div>
                    {selectedMessageId === message.id && (
                      <div className="message_actions">
                        {smsPermission && sentByUser && importance === "_" && user?.permissions?.sms !== false && (
                          <i
                            title={t('chat.sms_message')}
                            className="mr-2 clickable"
                            onClick={_.throttle(
                              () => this.props.makeMessageImportant(message),
                              2000
                            )}
                          >
                            <SMSIcon />
                          </i>
                        )}

                        {canDelete && (
                          <i
                            title={t('chat.del_msg')}
                            className="mr-2 clickable"
                            onClick={_.throttle(
                              () =>
                                this.props.removeMessage({
                                  messageId: message.id,
                                  twId: chatRoom.twId,
                                }),
                              2000
                            )}
                          >
                            <DeleteIcon />
                          </i>
                        )}
                        <CopyToClipboard text={clipboardText}>
                          <i
                            className="mr-2 clickable"
                            title={t('chat.copy_message')}
                            onClick={() =>
                              ToastrService.info(t('chat.massage_copied'))
                            }
                          >
                            <CopyIcon />
                          </i>
                        </CopyToClipboard>
                      </div>
                    )}
                    {sentByUser && message.attributes?.seen?.length > 0 && (
                      <MessageViewList
                        seen={message.attributes.seen}
                        acknowledge={message.attributes.acknowledge}
                        contacts={contacts}
                        isGroup={isGroup}
                      />
                    )}
                    {canAcknowledge &&
                      <button className="acknowledge"
                        onClick={() => this.props.acknowledgeMessage({ messageId: message.id, userId: user.id })}
                      >
                        {t('chat.acknowledge')}
                      </button>
                    }
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    });
  }

  Modal = ({ media, index }) => {
    const modal = useMediaGalleryModal(index);

    return (
      <MediaGalleryModal
        {...modal}
        media={media}
        isOpen={true}
        close={() => {
          this.setState({ mediaModal: {} });
        }}
      />
    );
  };

  render() {
    const { messages, loadingMore } = this.props;
    const { mediaModal } = this.state;
    return (
      <>
        <div
          className="chatMessagesContainer"
          ref={this.container}
          onScroll={_.debounce(this.handleScroll, 500)}
        >
          {loadingMore && (
            <div className="loadingMore">
              <Loader />
            </div>
          )}
          {!messages || !_.keys(messages).length
            ? this.renderNoMessageScreen()
            : this.renderMessages()}
        </div>
        {mediaModal.media && (
          <this.Modal media={mediaModal.media} index={mediaModal.index} />
        )}
      </>
    );
  }
}

export default withTranslation('common')(MessagesComponent)