import React from 'react'
import { Container } from "reactstrap";
import UsersComponent from "../../containers/Admin/User/UsersContainer";
import UnverifiedUserComponent from "../../containers/Admin/User/UnverifiedUsersContainer";
import { routes, userRoles } from "../../config";
import FacilitiesComponent from "../../containers/Admin/Facility/FacilityContainer";
import './index.scss'
import DepartmentsComponent from "../../containers/Admin/Department/DepartmentContainer";
import ClinicalRolesComponent from "../../containers/Admin/ClinicalRoles/ClinicalRolesContainer";
import { withRouter } from "react-router-dom";
import SpecialtiesComponent from "../../containers/Admin/Specialty/SpecialtyContainer";
import SuffixesComponent from "../../containers/Admin/Suffix/SuffixContainer";
import UserLogsComponent from "../../containers/Admin/User/UserLogsContainer";
import LogosContainer from '../../containers/Admin/Logos/LogosContainer';
import AboutTextsContainer from '../../containers/Admin/AboutTexts/AboutTextsContainer';
import EulaTextsContainer from '../../containers/Admin/EulaTexts/EulaTextsContainer';
import BannerMessage from './Components/BannerMessage/BannerMessage';
import { withTranslation } from 'react-i18next';
import LicensingContainer from '../../containers/Admin/Licensing/LicensingContainer';

class AdminComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activePage: '',
      openMenu: false
    }
  }

  componentDidMount() {
    const { location: { pathname } } = this.props
    const splittedUrl = pathname.split('/')
    const startPage = splittedUrl[splittedUrl.length - 1]
    this.setState({ activePage: startPage })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { location: { pathname } } = this.props
    const splittedUrl = pathname.split('/')
    const currentUrl = splittedUrl[splittedUrl.length - 1]
    if (this.state.activePage !== currentUrl) {
      this.setState({ activePage: currentUrl })
    }
  }

  setActivePage = pageName => {
    const { history } = this.props
    this.setState({ activePage: pageName })
    history.push(`${routes.admin.path}/${pageName}`)
  }

  toggleOpenMenu = () => {
    this.setState({ openMenu: !this.state.openMenu })
  }


  renderNavPanel = () => {
    const { userRole, licensing, t } = this.props;
    const isAdmin = userRole === userRoles.ADMIN;

    return (
      <div className='adminNavigation' onClick={this.toggleOpenMenu}>
        <div className='navigationHeader'>Sections <button><span>{this.state.activePage}</span> <img src='/assets/images/arrow-down.svg' alt='arrow'/></button> </div>
        <ul className={`pages ${this.state.openMenu ? 'open' : ''}`}>
          <li
            className={'adminNavLink' + (this.isUsersOpened ? ' active' : '')}
            onClick={() => {
              this.setActivePage(routes.users.pageName)
            }}
          >{t('Users')}
          </li>

          <li
            className={'adminNavLink' + (this.isunverifiedUsersOpened ? ' active' : '')}
            onClick={() => {
              this.setActivePage(routes.unverifiedUsers.pageName)
            }}
          >{t('UnverifiedUsers')}
          </li>

          <li
            className={'adminNavLink' + (this.isFacilitiesOpened ? ' active' : '')}
            onClick={() => {
              this.setActivePage(routes.facilities.pageName)
            }}
          >{t('admin.Facilities')}
          </li>
          {isAdmin &&
            <>
              <li
                className={'adminNavLink' + (this.isDepartmentsOpened ? ' active' : '')}
                onClick={() => {
                  this.setActivePage(routes.departments.pageName)
                }}
              >{t('admin.Departments')}
              </li>
              <li
                className={'adminNavLink' + (this.isClinicalRolesOpened ? ' active' : '')}
                onClick={() => {
                  this.setActivePage(routes.roles.pageName)
                }}
              >{t('cli_roles')}
              </li>
              <li
                className={'adminNavLink' + (this.isSpecialtiesOpened ? ' active' : '')}
                onClick={() => {
                  this.setActivePage(routes.specialties.pageName)
                }}
              >{t('admin.Specialties')}
              </li>
              <li
                className={'adminNavLink' + (this.isSuffixesOpened ? ' active' : '')}
                onClick={() => {
                  this.setActivePage(routes.suffixes.pageName)
                }}
              >{t('admin.Suffixes')}
              </li>
              <li
                className={'adminNavLink' + (this.isLogosOpened ? ' active' : '')}
                onClick={() => {
                  this.setActivePage(routes.logos.pageName)
                }}
              >{t('Logos')}
              </li>
              <li
                className={'adminNavLink' + (this.isAboutTextsOpened ? ' active' : '')}
                onClick={() => {
                  this.setActivePage(routes.about.pageName)
                }}
              >{t('admin.about_us_content')}
              </li>
              <li
                className={'adminNavLink' + (this.isEulaTextsOpened ? ' active' : '')}
                onClick={() => {
                  this.setActivePage(routes.eulaContent.pageName)
                }}
              >{t('admin.eula_content')}
              </li>
            </>
          }
          <li
            className={'adminNavLink' + (this.isUserLogsOpened ? ' active' : '')}
            onClick={() => {
              this.setActivePage(routes.logs.pageName)
            }}
          >{t('admin.user_logs')}
          </li>
          {licensing.banner && <li
            className={'adminNavLink' + (this.isBannerOpen ? ' active' : '')}
            onClick={() => {
              this.setActivePage(routes.bannerMessage.pageName)
            }}
          >{t('admin.banner')}
          </li>}
          {this.props.userIsSuperAdmin && <li
            className={'adminNavLink' + (this.licesingOpen ? ' active' : '')}
            onClick={() => {
              this.setActivePage(routes.licensing.pageName)
            }}
          >{t('admin.licensing')}
          </li>}
        </ul>
      </div>
    )
  }

  render() {
    this.isUsersOpened = this.state.activePage === routes.users.pageName
    this.isunverifiedUsersOpened = this.state.activePage === routes.unverifiedUsers.pageName
    this.isFacilitiesOpened = this.state.activePage === routes.facilities.pageName
    this.isDepartmentsOpened = this.state.activePage === routes.departments.pageName
    this.isClinicalRolesOpened = this.state.activePage === routes.roles.pageName
    this.isSpecialtiesOpened = this.state.activePage === routes.specialties.pageName
    this.isSuffixesOpened = this.state.activePage === routes.suffixes.pageName
    this.isLogosOpened = this.state.activePage === routes.logos.pageName
    this.isAboutTextsOpened = this.state.activePage === routes.about.pageName
    this.isEulaTextsOpened = this.state.activePage === routes.eulaContent.pageName
    this.isUserLogsOpened = this.state.activePage === routes.logs.pageName
    this.isBannerOpen = this.state.activePage === routes.bannerMessage.pageName
    this.licesingOpen = this.state.activePage === routes.licensing.pageName

    const isAdmin = this.props.userRole === userRoles.ADMIN;

    const { banner } = this.props.licensing

    return (
      <Container fluid className="pageContainer adminContainer">
        <div className="contentContainer d-flex flex-column px-0">
          <div className="adminHeaderContainer">
            <h1>Administration</h1>
          </div>
          <div className="bodyContainer adminComponent">
            {this.renderNavPanel()}
            {this.isUsersOpened && <UsersComponent />}
            {this.isunverifiedUsersOpened && <UnverifiedUserComponent />}
            {this.isFacilitiesOpened && <FacilitiesComponent />}
            {isAdmin &&
              <>
                {this.isDepartmentsOpened && <DepartmentsComponent />}
                {this.isClinicalRolesOpened && <ClinicalRolesComponent />}
                {this.isSpecialtiesOpened && <SpecialtiesComponent />}
                {this.isSuffixesOpened && <SuffixesComponent />}
                {this.isLogosOpened && <LogosContainer />}
                {this.isAboutTextsOpened && <AboutTextsContainer />}
                {this.isEulaTextsOpened && <EulaTextsContainer />}
              </>
            }
            {banner && this.isBannerOpen && <BannerMessage />}
            {this.isUserLogsOpened && <UserLogsComponent />}
            {this.props.userIsSuperAdmin && this.licesingOpen && <LicensingContainer />}
          </div>
        </div>
      </Container>
    )
  }
}

export default withTranslation()(withRouter(AdminComponent))
