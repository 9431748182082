import React, { Component } from 'react';
import {
  Button,
  ButtonDropdown,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { withRouter } from "react-router-dom";
import { UserAvatar, ShowMore } from "../UIComponents";
import { chatStatusClassName, contactDisplayName } from "../../helpers/chat";
import { appTheme, routes, callStatuses, callStatusesForParticipant, callRoomEventType } from "../../config";
import _ from "lodash";
import { SocketService } from "../../services";
import { isMobile } from "../../helpers/function";
import { withTranslation } from 'react-i18next';

class HeaderComponent extends Component {
  state = {
    isSettingActive: false,
    openSettings: false,
    isNotAvailableModalOpen: false,
    openButtonsList: false,
    canJoin: true,
    isShowMoreTooltipOpen: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      chatRoom: {
        twId,
        call
      },
      calls
    } = this.props;
    if (prevProps.calls.length > calls.length) {
      // Previously we had at least one additional call
      const hasCurrentCall = calls.some((c) => (c.twId === twId))
      if (!hasCurrentCall) {
        this.setState({
          canJoin: true
        })
      }
    }
  }

  handleSetUnsetFavorite = (selectedChatRoom) => {
    if (selectedChatRoom) {
      if (selectedChatRoom.isFavorite) {
        this.props.unsetChatRoomAsFavorite(selectedChatRoom);
      } else {
        this.props.setChatRoomAsFavorite(selectedChatRoom);
      }
    }
  }

  handleLeaveChat = (chatRoom) => {
    if (this.props.user?.id !== chatRoom?.owner) {
      this.props.leaveChatRoom(chatRoom);
    }
  }

  handleDeleteChat = () => {
    if (this.props.user?.id === this.props.chatRoom?.owner) {
      this.props.toggleDeleteModal()
    }
  }

  toggleDropdown = (name) => {
    this.setState({ [name]: !this.state[name] })
  };

  changeChatRoomMuteStatus = () => {
    if (this.props.chatRoom.isMuted) {
      this.props.unMuteChatRoom(this.props.chatRoom.twId)
    } else {
      this.props.muteChatRoom(this.props.chatRoom.twId)
    }
  };

  getChatRoomName(chat) {
    if (chat.type && chat.type === 'direct') {
      const contact = this.props.contacts.find(x => chat.participants.find(contact => contact.id === x.id && contact.id !== this.props.user.id));
      if (contact) {
        return contactDisplayName(contact);
      } else {
        return chat.name;
      }
    } else {
      return chat.name;
    }
  }

  canMakeCall = (type) => {
    const { chatRoom, user, contacts } = this.props;
    return chatRoom?.participants?.some(participant => {
      if (participant.id === user.id) {
        return false
      }
      const contact = contacts.find(contact => contact.id === participant.id);
      if (!contact?.permissions) return false;

      return contact.permissions[type]
    })
  };

  initializeVideoCall = () => {
    if (!this.canMakeCall("video")) {
      this.toggleIsNotAvailableModal();
      return
    }
    this.props.initializeCall({
      twId: this.props.chatRoom?.twId,
      video: true,
      voice: true,
    })
  }

  initializeVoiceCall = () => {
    if (!this.canMakeCall("voice")) {
      this.toggleIsNotAvailableModal();
      return
    }

    this.props.initializeCall({
      twId: this.props.chatRoom?.twId,
      video: false,
      voice: true,
    })
  }

  initializeDirectScreenShare = () => {
    this.props.initializeDirectScreenShare({
      twId: this.props.chatRoom?.twId,
      video: false,
      voice: true,
    })
  }


  addMemberToChat = () => {
    const { chatRoom = {}, openGroupModal } = this.props;

    if (chatRoom?.type === 'direct') {
      openGroupModal({
        selectedGroup: { ...chatRoom, name: "" }
      });
    } else {
      openGroupModal({ selectedGroup: chatRoom });
    }
  };

  toggleIsNotAvailableModal = () => {
    this.setState({ isNotAvailableModalOpen: !this.state.isNotAvailableModalOpen })
  }

  handleJoinCall = (onlyVoice = true) => {
    const { chatRoom: { twId, call }, acceptCall } = this.props;
    this.setState({ canJoin: false }, () => {
      acceptCall(twId, onlyVoice, { ...call, twId })
    })
  }

  renderOngoingCallButtons = () => {
    const { chatRoom: { call }, t } = this.props;
    const { canJoin } = this.state;

    if (call.type === callRoomEventType.SCREEN_SHARE) {
      return (
        <div className="d-flex align-items-center justify-content-center ongoingCallContainer">
          <p className="ongoingCallTitle">{t('chat.ongoing_screen_share')}</p>
          <div className="ongoingCallControls">
            <button disabled={!canJoin} onClick={() => this.handleJoinCall(true)}>
              {t('Join')}
            </button>
          </div>
        </div>
      )
    }

    const onlyVoice = call.type === callRoomEventType.VOICE;

    return (
      <div className="d-flex align-items-center justify-content-center ongoingCallContainer">
        <p className="ongoingCallTitle">{('Ongoing_Call')}</p>
        <div className="ongoingCallControls">
          {!onlyVoice && <button disabled={!canJoin} onClick={() => this.handleJoinCall(false)}>
            {t('Join')}
          </button>}
          <button disabled={!canJoin} onClick={() => this.handleJoinCall(true)}>
            {t('chat.join_with_voice')}
          </button>
        </div>
      </div>
    )

  }

  renderOngoingCalContent() {
    const { calls, chatRoom, user } = this.props;

    const acceptedCallInCurrentChatRoom = calls?.find(call => call.twId === chatRoom?.twId);

    if (acceptedCallInCurrentChatRoom?.status === callStatuses.STARTED &&
      acceptedCallInCurrentChatRoom.participants?.find(participant => participant.id === user.id && SocketService.connection?.io?.opts?.query?.uuid !== participant.socketId)) {
      return <p className="ongoingCallTitle">You are currently in the call in this chat room with another device.</p>
    }

    if (!!chatRoom.call?.participants?.filter(participant => participant.id !== user.id && participant.status === callStatusesForParticipant.ACCEPTED)?.length && chatRoom.call?.status !== "ended") {
      return this.renderOngoingCallButtons()
    }

    return null
  }

  renderChatHeaderButtons() {
    const { chatRoom = {}, contacts = [], selectedItemId = '', changingMuteStatus,
      user, isChatDisabled, calls, theme, areCallsRejected, licensing, t } = this.props;
    let selectedContact = selectedItemId ? contacts.find(contact => contact.id === selectedItemId) : {};
    const { openSettings, openButtonsList } = this.state;
    let chatAvatar = { avatar: '' };
    let isAtLeastOneParticipantCanReceiveVoiceCall = false;
    let isAtLeastOneParticipantCanReceiveVideoCall = false;
    let isAtLeastOneParticipantCanReceiveDirectScreenShare = false;
    // let shouldShowContactInformation = chatRoom.type === 'direct';

    if (chatRoom.type !== 'direct') {
      chatAvatar.avatar = chatRoom.avatar;
    }

    if (selectedItemId) {
      if (!chatAvatar.avatar) {
        chatAvatar.avatar = selectedContact.avatar;
      }
      chatAvatar.firstName = selectedContact.firstName;
      chatAvatar.lastName = selectedContact.lastName;
      const contact = contacts.find(contact => contact.id === selectedItemId)
      if (contact?.connected) {
        isAtLeastOneParticipantCanReceiveVoiceCall = contact.permissions?.voice;
        isAtLeastOneParticipantCanReceiveVideoCall = contact.permissions?.video;
        isAtLeastOneParticipantCanReceiveDirectScreenShare = true;
      }
    } else if (chatRoom.participants?.length === 2) {
      selectedContact = chatRoom.participants.find(item => item && item.id !== user.id);
      if (selectedContact) {
        selectedContact = contacts.find(contact => contact && contact.id === selectedContact.id);
        if (selectedContact) {
          if (!chatAvatar.avatar) {
            chatAvatar.avatar = selectedContact.avatar;
          }
          chatAvatar.firstName = selectedContact.firstName;
          chatAvatar.lastName = selectedContact.lastName;
          if (selectedContact?.connected) {
            isAtLeastOneParticipantCanReceiveVoiceCall = selectedContact.permissions?.voice;
            isAtLeastOneParticipantCanReceiveVideoCall = selectedContact.permissions?.video;
            isAtLeastOneParticipantCanReceiveDirectScreenShare = true;
          }
        }
      }
    } else {
      if (!chatAvatar.avatar) {
        chatAvatar.avatar = chatRoom.avatar;
      }
      isAtLeastOneParticipantCanReceiveVoiceCall = chatRoom.participants?.some(participant => {
        const contact = contacts.find(contact => contact.id === participant.id);
        return contact?.connected && contact.permissions?.voice
      });
      isAtLeastOneParticipantCanReceiveVideoCall = chatRoom.participants?.some(participant => {
        const contact = contacts.find(contact => contact.id === participant.id);
        return contact?.connected && contact.permissions?.video
      });
      isAtLeastOneParticipantCanReceiveDirectScreenShare = chatRoom.participants?.some(participant => {
        const contact = contacts.find(contact => contact.id === participant.id);
        return contact?.connected
      });
    }

    const isExistingCall = chatRoom.call?.status === callStatuses.INITIALIZED ||
      chatRoom.call?.status === callStatuses.STARTED ||
      calls.some(call => call.status === callStatuses.INITIALIZED || call.status === callStatuses.STARTED);

    const isMobileDevice = isMobile();

    const canMakeVoiceCall = licensing.voiceCalls && !isExistingCall && isAtLeastOneParticipantCanReceiveVoiceCall && user?.permissions?.voice && !areCallsRejected;
    const canMakeVideoCall = licensing.video && !isExistingCall && isAtLeastOneParticipantCanReceiveVideoCall && user?.permissions?.video && !areCallsRejected;
    const canMakeDirectScreenShare = !isMobileDevice && !isExistingCall && isAtLeastOneParticipantCanReceiveDirectScreenShare && !areCallsRejected;

    return (
      <>
        <div className={`btnRounded chatHeaderButtonsToggleButton ml`}
          onClick={() => this.setState({ openButtonsList: !openButtonsList })}>
          <img src={`assets/images/btn-more-grey.svg`} alt="more" />
        </div>
        <div className={`chatHeaderOptions${openButtonsList ? " open" : ""}`}>
          <ButtonDropdown isOpen={openSettings} toggle={() => this.toggleDropdown('openSettings')}>
            <DropdownToggle className='settingsDropdownIcon'>
              <img
                src={`/assets/images/gear-${openSettings ? 'blue' : 'grey'}${!theme || theme === appTheme.LIGHT ? "" : "-dark"}.svg`}
                width="25" alt="gear" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                disabled={isChatDisabled || changingMuteStatus}
                onClick={this.changeChatRoomMuteStatus}>{chatRoom.isMuted ? t('chat.Unmute') : t('chat.Mute')}
              </DropdownItem>
              {chatRoom.type === 'group' && this.props.user?.id !== chatRoom?.owner &&
                <DropdownItem onClick={() => this.handleLeaveChat(chatRoom)}>{t('Leave_Chat')}</DropdownItem>}
              {chatRoom.type === 'group' && this.props.user?.id === chatRoom?.owner &&
                <DropdownItem onClick={() => {
                  this.handleDeleteChat(chatRoom.twId)
                }}>{t('chat.del_group')}</DropdownItem>}
            </DropdownMenu>
          </ButtonDropdown>
          {!isChatDisabled && (chatRoom.type === 'direct' || user?.id === chatRoom.owner) &&
            <div className={`btnRounded`} onClick={this.addMemberToChat}>
              <img src="/assets/images/add-member-white.svg" width="20" alt="add" />
            </div>
          }
          {!isChatDisabled &&
            <>
              <div id='callButtons'>
                <div className='callButtonsWrap'>
                  {licensing.video && <div className={`btnRounded ml${!canMakeVideoCall ? ' disable' : ''}`}
                    onClick={_.debounce(() => canMakeVideoCall && this.initializeVideoCall(), 1000)}>
                    <img src="/assets/images/call-video.svg" width="20" alt="video" />
                  </div>}

                  {licensing.voiceCalls && <div className={`btnRounded ml${!canMakeVoiceCall ? ' disable' : ''}`}
                    onClick={_.debounce(() => canMakeVoiceCall && this.initializeVoiceCall(), 1000)}>
                    <img src="/assets/images/call-voice.svg" width="20" alt="voice" />
                  </div>}

                  <div className={`btnRounded initializeScreenShareButton ml${!canMakeDirectScreenShare ? ' disable' : ""}`}
                    onClickCapture={_.debounce(() => canMakeDirectScreenShare && this.initializeDirectScreenShare(), 1000)}>
                    {!areCallsRejected && !!isMobileDevice && <div className='callButtonsTooltip screenShareDisabled'>Your browser doesnt support this functionality.</div>}
                    <img src="/assets/images/direct-screen-sharing.svg" width="20" alt="screen share" />
                  </div>
                </div>
                {!!areCallsRejected &&
                  <div className='callButtonsTooltip'>Your browser doesnt support this functionality.</div>}
              </div>
            </>
          }
        </div>
      </>
    )
  }

  render() {
    const { chatRoom = {}, contacts = [], selectedItemId = '', user, theme, t } = this.props;
    let selectedContact = selectedItemId ? contacts.find(contact => contact.id === selectedItemId) : {};
    const { isNotAvailableModalOpen, isShowMoreTooltipOpen } = this.state;
    const isGroup = chatRoom.type === 'group';
    let chatAvatar = { avatar: '', avatarS3Key: '' };
    let shouldShowContactInformation = chatRoom.type === 'direct';

    if (chatRoom.type !== 'direct') {
      chatAvatar.avatar = chatRoom.avatar;
    }

    if (selectedItemId) {
      if (!chatAvatar.avatar) chatAvatar.avatar = selectedContact.avatar;
      if (!chatAvatar.avatarS3Key) chatAvatar.avatarS3Key = selectedContact.avatarS3Key;

      chatAvatar.firstName = selectedContact.firstName;
      chatAvatar.lastName = selectedContact.lastName;
    } else if (chatRoom.participants?.length === 2) {
      shouldShowContactInformation = true;
      selectedContact = chatRoom.participants.find(item => item && item.id !== user.id);

      if (selectedContact) {
        selectedContact = contacts.find(contact => contact && contact.id === selectedContact.id);
        if (selectedContact) {
          if (!chatAvatar.avatar) chatAvatar.avatar = selectedContact.avatar;
          if (!chatAvatar.avatarS3Key) chatAvatar.avatarS3Key = selectedContact.avatarS3Key;
        
          chatAvatar.firstName = selectedContact.firstName;
          chatAvatar.lastName = selectedContact.lastName;
        }
      }
    } else {
      if (!chatAvatar.avatar) {
        chatAvatar.avatar = chatRoom.avatar;
      }
    }

    const isContactPage = this.props.location?.pathname === routes.contacts?.path;

    const noContactInfo = !selectedContact ||
      (
        !selectedContact.departments?.length &&
        !selectedContact.facilities?.length &&
        !selectedContact.professions?.length &&
        !selectedContact.specialties?.length &&
        !selectedContact.suffixes?.length
      );

    return (
      <Container fluid className="chatHeaderContainer">
        <div className='d-flex align-items-center'>
          <span onClick={() => this.props.onExitChat()} className='backButtonHidden'>
            <img className='mr-2' alt=''
              src={`assets/images/btn-arrow-left${theme === appTheme.DARK ? "-grey" : "-blue"}.svg`} />
          </span>

          <div className="position-relative">
            {!isContactPage &&
              <div onClick={() => this.handleSetUnsetFavorite(chatRoom)}
                className={chatRoom?.isFavorite ? "favouriteChatRoom" : "unFavouriteChatRoom"} />
            }
            <UserAvatar user={chatAvatar}
              chatRoomType={chatRoom.type}
              showGroupMembersToggle={this.props.showGroupMembersToggle}
              className={`chatHeaderAvatarContainer mr-2 ${selectedContact?.chatStatus ? `${selectedContact?.connected ? chatStatusClassName(selectedContact.chatStatus) : 'Offline'} withStatus` : ''}`} />
          </div>
          <div className="chatInfoContainer">
            <div className="d-flex align-items-center">
              <h3>{selectedItemId ? contactDisplayName(selectedContact) : this.getChatRoomName(chatRoom)}</h3>
              {shouldShowContactInformation && (
                <ButtonDropdown disabled={noContactInfo} isOpen={!noContactInfo}
                  toggle={() => { }}
                  className={`${!noContactInfo ? "enabled " : ""}contactInfoDropdownContainer${isShowMoreTooltipOpen ? ' opened' : ''}`}>
                  <DropdownToggle className={`${noContactInfo ? "disabled " : ""}settingsDropdownIcon`}>
                    <div className={"contactInfoButton"} />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem header>Member Info</DropdownItem>
                    {
                      !!selectedContact?.facilities?.length && (
                        <DropdownItem toggle={false}>
                          <span>
                            <ShowMore setIsOpen={isOpen => this.setState({ isShowMoreTooltipOpen: isOpen })} items={selectedContact.facilities?.map(i => i.name)} name={"member-info-facilities"} />
                          </span>
                          <p>{t('admin.Facilities')}</p>
                        </DropdownItem>
                      )
                    }
                    {
                      !!selectedContact?.departments?.length && (
                        <DropdownItem toggle={false}>
                          <span>
                            <ShowMore setIsOpen={isOpen => this.setState({ isShowMoreTooltipOpen: isOpen })} items={selectedContact.departments?.map(i => i.name)} name={"member-info-departments"} />
                          </span>
                          <p>{t('admin.Departments')}</p>
                        </DropdownItem>
                      )
                    }
                    {
                      !!selectedContact?.professions?.length && (
                        <DropdownItem toggle={false}>
                          <span>
                            <ShowMore setIsOpen={isOpen => this.setState({ isShowMoreTooltipOpen: isOpen })} items={selectedContact.professions?.map(i => i.name)} name={"member-info-professions"} />
                          </span>
                          <p>{t('cli_role')}</p>
                        </DropdownItem>
                      )
                    }
                    {
                      !!selectedContact?.specialties?.length && (
                        <DropdownItem toggle={false}>
                          <span>
                            <ShowMore setIsOpen={isOpen => this.setState({ isShowMoreTooltipOpen: isOpen })} items={selectedContact.specialties?.map(i => i.name)} name={"member-info-specialties"} />
                          </span>
                          <p>{t('admin.Specialties')}</p>
                        </DropdownItem>
                      )
                    }
                    {
                      !!selectedContact?.suffixes?.length && (
                        <DropdownItem toggle={false}>
                          <span>{selectedContact.suffixes[0].name}</span>
                          <p>{t('Suffix')}</p>
                        </DropdownItem>
                      )
                    }
                  </DropdownMenu>
                </ButtonDropdown>
              )}
            </div>
            {!isGroup && <p>{t(selectedContact?.connected ? selectedContact?.chatStatus : "Offline")}</p>}
          </div>
        </div>
        {this.renderOngoingCalContent()}
        {this.renderChatHeaderButtons()}
        <Modal isOpen={isNotAvailableModalOpen} toggle={this.toggleIsNotAvailableModal} className={"notAvailableModal"}
          size="sm">
          <ModalBody className="d-flex justify-content-center align-items-center px-4 pb-0">
            <p className={"mb-0"}>🤔 Contact is not available for this call.</p>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-center">
            <Button color="primary" onClick={this.toggleIsNotAvailableModal}>{t('CLOSE')}</Button>
          </ModalFooter>
        </Modal>
      </Container>
    )
  }
}

export default withTranslation()(withRouter(HeaderComponent));
