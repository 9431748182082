import React, { } from "react"
import { Container, Button } from "reactstrap";

import { CheckboxComponent } from "../../../UIComponents";
import "./Licensing.scss"
import { withTranslation } from "react-i18next";
import { ClipLoader } from "react-spinners";
import { ToastrService } from "../../../../services";
import i18n from "../../../../i18n";
import { withRouter } from "react-router-dom";

class Licensing extends React.PureComponent {
  state = {
    chat: undefined,
    voiceCalls: undefined,
    video: undefined,
    screenshare: undefined,
    banner: undefined,
    voiceMessage: undefined,
    voiceToText: undefined,
    sendSMS: undefined,
  }

  componentDidMount() {
    if (!this.props.userIsSuperAdmin) {
      this.props.history.push('/admin/users');
    }

    if (!this.props.licensing.chat) {
      this.props.fetchAppSettings()
    } else {
      this.setState({ ...this.props.licensing });
    }
  }

  componentDidUpdate(prevProps) {
    const isUpdated = Object.keys(prevProps.licensing).some((x) => this.props.licensing[x] !== prevProps.licensing[x]) ;
    if (isUpdated || typeof this.state.chat === 'undefined') {
      this.setState({ ...this.props.licensing });
    }
  }

  saveFeatures() {
    const isUpdated = Object.keys(this.props.licensing).some((x) => this.props.licensing[x] !== this.state[x]);
    if (isUpdated) this.props.updateLicensing(this.state);
    else ToastrService.success('Licensing settings saved')
  }

  render() {
    const { loading } = this.props;

    const {
      chat, voiceCalls, video, screenshare,
      banner, voiceMessage, voiceToText, sendSMS
    } = this.state;

    return <Container fluid className="pageContainer Licensing">
      <div className="bodyHeader">
        <h2>{i18n.t('admin.licensing')}</h2>
        <Button onClick={() => this.saveFeatures()} className="submitButton">
          {loading && <ClipLoader size={14} color={'#fff'} loading />} {i18n.t('Save')}
        </Button>
      </div>

      <h5>{i18n.t('admin.select_features_available')}</h5>

      <CheckboxComponent
        checked={chat || true}
        disabled
        className='CheckBox_'
        label={i18n.t('admin.chat')}
        onChange={(chat) => {
          this.setState({ chat, updated: true })
        }}
      />
      <CheckboxComponent
        checked={voiceCalls}
        className='CheckBox_'
        label={i18n.t('admin.voice_calls')}
        onChange={(voiceCalls) => {
          this.setState({ voiceCalls, updated: true })
        }}
      />
      <CheckboxComponent
        checked={video}
        className='CheckBox_'
        label={i18n.t('admin.video')}
        onChange={(video) => {
          this.setState({ video, updated: true })
        }}
      />
      <CheckboxComponent
        checked={screenshare || true}
        className='CheckBox_'
        disabled
        label={i18n.t('admin.screenshare')}
        onChange={(screenshare) => {
          this.setState({ screenshare, updated: true })
        }}
      />

      <CheckboxComponent
        checked={banner}
        className='CheckBox_'
        label={i18n.t('admin.banner_messaging')}
        onChange={(banner) => {
          this.setState({ banner, updated: true })
        }}
      />

      <CheckboxComponent
        checked={voiceMessage}
        className='CheckBox_'
        label={i18n.t('admin.voice_message')}
        onChange={(voiceMessage) => {
          this.setState({ voiceMessage, updated: true })
        }}
      />
      <CheckboxComponent
        checked={voiceToText}
        className='CheckBox_'
        label={i18n.t('admin.voice_to_text')}
        onChange={(voiceToText) => {
          this.setState({ voiceToText, updated: true })
        }}
      />
      <CheckboxComponent
        checked={sendSMS}
        className='CheckBox_'
        label={i18n.t('admin.send_sms')}
        onChange={(sendSMS) => {
          this.setState({ sendSMS, updated: true })
        }}
      />
    </Container>
  }
}

export default withTranslation('common')(withRouter(Licensing))
