import React, { useEffect, useState } from "react";
import { Container, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "./AboutTextComponent.scss";
import RichTextEditor from "./RichTextEditor/RichTextEditor";
import { ContentsApiService, ToastrService } from "../../../../services";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import { LanguageSelector } from "../../../UIComponents/LanguageSelector";
import { Loader } from "../../../UIComponents";

const AboutTextComponent = ({ selectedTheme }) => {
  const [state, setState] = useState({});
  const [isLoading, setIsLoading] = useState(false)
  const [lang, setLang] = useState(i18n.language)
  const [t] = useTranslation();
  const [canPublish, setCanPublish] = useState(false)
  const [editorKey, setEditorKey] = useState(0)
  const [showResetConfirmation, setShowResetConfirmation] = useState(false)

  useEffect(() => {
    let isSubscribed = true;

    const getData = () => {
      setIsLoading(true)
      new ContentsApiService().getDraftAboutTexts()
      .then((res) => {
        if (isSubscribed) {
          setState({
            aboutUsText: res.data.aboutUsText || {},
            informationText: res.data.informationText || {},
            cachedData: res.data,
          })
        }
      })
      .catch((err) => {
        if (isSubscribed) {
          const errorMessage = err?.message || 'An error occurred while fetching the content';
          ToastrService.error(errorMessage);
          console.error('Error fetching about texts:', err);
        }
      })
      .finally(() => {
        if (isSubscribed) {
          setIsLoading(false)
        }
      })
    }

    getData();

    return () => {
      isSubscribed = false;
    };
  }, [])

  const saveData = () => {
    if (state.aboutUsText || state.informationText) {
      setIsLoading(true)

      new ContentsApiService()
        .updateDraftAboutTexts(state)
        .then((res) => {
          setCanPublish(true)
          ToastrService.success(t("admin.text_edit"))
        })
        .catch((err) => {
          const errorMessage = err?.message || 'An error occurred while saving the content';
          ToastrService.error(errorMessage);
          console.error('Error saving about texts:', err);
        })
        .finally(() => {
          setIsLoading(false)
        });
    }
  };

  const resetData = () => {
    if (state.cachedData) {
      setState({
        ...state,
        aboutUsText: state.cachedData.aboutUsText || {},
        informationText: state.cachedData.informationText || {},
        update: false,
        cachedData: state.cachedData
      });
      setEditorKey(prevKey => prevKey + 1)
    }
  }

  const editorProps = (key, lang) => ({
    value: state?.[key]?.[lang],
    onChange: (value) => setState({ ...state, [key]: { ...state[key], [lang]: value }, update: true }),
    darkMode: selectedTheme === 'dark'
  })

  const publishData = () => {
    setIsLoading(true)
    new ContentsApiService().publishAboutTexts(state)
    .then((res) => {
      ToastrService.success(t("admin.text_publish"))
      setCanPublish(false)
    })
    .catch((err) => {
      const errorMessage = err?.message || 'An error occurred while publishing the content';
      ToastrService.error(errorMessage);
      console.error('Error publishing about texts:', err);
    })
    .finally(() => {
      setIsLoading(false)
    })
  }

  const toggleResetConfirmation = () => {
    setShowResetConfirmation(!showResetConfirmation);
  };

  const handleResetClick = () => {
    toggleResetConfirmation();
  };

  const confirmReset = () => {
    resetData();
    toggleResetConfirmation();
  };

  return (
    <Container fluid className="AboutTextComponent">
      <div className="suffixesPage">
        <div className="bodyContent">
          {isLoading && (
            <div className="loader-overlay">
              <Loader />
            </div>
          )}
          <LanguageSelector onChange={setLang} value={lang} />
          <br />
          <br />
          <div className="text-editor">
            <div className="bodyHeader">
              <h2>{t("admin.about_us")}</h2>
            </div>
            <div className="content">
              <div className="preview">
                {lang === 'en' ? <RichTextEditor key={editorKey} {...editorProps('aboutUsText', 'en')} /> : <RichTextEditor key={editorKey + 1} {...editorProps('aboutUsText', 'fr')} />}
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <div className="text-editor">
            <div className="bodyHeader">
              <h2>{t("admin.info_pg_txt")}</h2>
            </div>
            <div className="content">
              <div className="preview">
                {lang === 'en' ? <RichTextEditor key={editorKey} {...editorProps('informationText', 'en')} /> : <RichTextEditor key={editorKey + 1} {...editorProps('informationText', 'fr')} />}
              </div>
              <br />
              <div className="btn-content">
                <Button
                  className="submitButton draftButton"
                  color="primary"
                  disabled={!state.update}
                  onClick={handleResetClick}
                >
                  Reset
                </Button>

                <Button
                  className="submitButton draftButton"
                  disabled={!state.update}
                  onClick={saveData}
                >
                  Save Draft
                </Button>

                <Button
                  className="submitButton cancelButton"
                  disabled={!canPublish}
                  onClick={publishData}
                >
                  Publish
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={showResetConfirmation} toggle={toggleResetConfirmation} size="sm">
        <ModalHeader toggle={toggleResetConfirmation}>{t('CONFIRM')}</ModalHeader>
        <ModalBody>
          {t('admin.confirm_reset')}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleResetConfirmation}>{t('CANCEL')}</Button>
          <Button color="primary" onClick={confirmReset}>{t('CONFIRM')}</Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default AboutTextComponent;
